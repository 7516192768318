import type { HandleServerError } from '@sveltejs/kit'
import { track } from '@vercel/analytics'
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
    dsn: 'https://8c0a3ccfafa36668aa4cb9f3dff76ee0@o4505591182196736.ingest.sentry.io/4505903808708608',
    tracesSampleRate: 1.0,
    ignoreErrors: ['TypeError'],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    environment:
        import.meta.env.VITE_API_URL?.includes('prod') && import.meta.env.PROD
            ? 'production'
            : 'development',

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [Sentry.replayIntegration({})],
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
// eslint-disable-next-line @typescript-eslint/naming-convention
export const handleError: HandleServerError = ({ error, event }) => {
    track('client error', { data: JSON.stringify({ error, event }) })
    Sentry.captureException({ data: JSON.stringify({ error, event }) })
}
